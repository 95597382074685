require(['jquery', 'js/glider-min'], function ($) {
	"use strict";
// Toggle Product Features
	const triggers = $('.js-features-trigger'),
		itemFeatures = '.js-product-form',
		itemInfo = '.js-product-slider-item-info',
		triggerOpen = '.js-features-trigger-open';

	triggers.click(function () {
		const slide = $(this).closest('.js-product-slider-slide');

		$(slide).find(itemFeatures).toggleClass('is-visible');
		$(slide).find(itemInfo).toggleClass('is-hidden');
		$(slide).find(triggerOpen).toggleClass('is-hidden');
		$(slide).find('.price-title-configurable').toggleClass('is-visible');
		$(slide).find('.price-box').toggleClass('is-visible');
		const swatch = $(slide).find('.swatch-attribute');
		const label = $(slide).find('.swatch-attribute-label');
		const swatchColor = $(slide).find('.swatch-attribute.color');
		const priceBox = $(slide).find('.price-box__label');
		swatch.each(function () {
			$(this).css('display', 'none');
		});
		label.css('display','flex');
		swatch.css('display','flex');
		swatchColor.css('display', 'flex');
		priceBox.css('display','flex');
		if (!$(slide).find(itemFeatures).hasClass('is-visible')) {
			$(slide).find(itemFeatures).trigger('reset');
			$(slide).find('.swatch-option').removeClass('selected');
			swatch.each( function() {
				$(this).find('.swatch-option').each( function() {
					$(this).removeClass('selected');
					$(this).removeClass('disabled');
					$(this).attr('aria-checked', false);
					$(this).attr('disabled', false);
				});
				$(this).css('display', 'none');
				swatchColor.css('display', 'flex');
				label.css('display','none');
				priceBox.css('display','none');
				$(this).removeAttr('option-selected');
			});
		}
	});

// Form Validations
	const submitBtn = $('.js-submit-form');

	submitBtn.click(function () {
		const form = $(this).closest('.js-product-form'),
			age = $(form).find('.groesse .swatch-option'),
			color = $(form).find('.color .swatch-option'),
			errorAge = $(form).find('.js-product-form-error-age'),
			errorColor = $(form).find('.js-product-form-error-color');


		let ageCheck = 0;
		for (let i = 0; i < age.length; i++) {
			if (age[i].classList.contains('selected')) {
				ageCheck++;
				break;
			}
		}
	})

// Slider settings
	const productSliders = document.querySelectorAll(".js-product-slider");
	if (productSliders) {
		productSliders.forEach(function (productSlider) {
			let slider = productSlider.querySelector('.slides'),
				slidesToShow = slider.getAttribute('data-slides-to-show'),
				sliderPrev = productSlider.querySelector('.js-product-slider-prev'),
				sliderNext = productSlider.querySelector('.js-product-slider-next'),
				bullets = productSlider.querySelector('.js-product-slider-bullets');

			if (slidesToShow === '0') {
				slidesToShow = '4';
			}

			new Glider(slider, {
				dots: bullets,
				scrollLock: true,
				draggable: true,
				arrows: {
					prev: sliderPrev,
					next: sliderNext
				},
				responsive: [
					{
						breakpoint: 0,
						settings: {
							slidesToShow: 1.5,
							slidesToScroll: 1.5,
							itemWidth: 'auto'
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2.5,
							slidesToScroll: 2.5,
							itemWidth: 'auto'
						}
					},
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: slidesToShow,
							slidesToScroll: 1,
							itemWidth: 'auto'
						}
					},
				]
			});
		})
	}
});
